<template>
  <div class="q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['faceScarsAppearance'])">
      <single-picture-select
        class="q-mb24"
        :title="title"
        :value="faceScarsAppearance"
        :options="$options.acneScarAppearance"
        hint="info.scarsAppearance"
        @input="onFieldChange('faceScarsAppearance', $event)"
      />
    </question-with-error-wrap>
  </div>
</template>

<script>
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';
import SinglePictureSelect from '@/modules/questionnaire/new-design-components/questions/SinglePictureSelect.vue';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import { ACNE_SCAR_APPEARANCE_OPTIONS } from '@/modules/questionnaire/constants/steps/skinAppearance';

export default {
  name: 'AcneScarsTemplate',
  components: { SinglePictureSelect, QuestionWithErrorWrap },
  mixins: [stepTemplateMixin],
  acneScarAppearance: ACNE_SCAR_APPEARANCE_OPTIONS,
  props: {
    faceScarsAppearance: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  }
};
</script>
